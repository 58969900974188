import React from "react"
import { OutlineField as UsuariField } from "../../../../library/Textfield"
import Label from "../../../../library/Label"

const Usuari = () => {

    const usuari = {
        type: "text",
        id: "usuari",
        placeholder: "El teu usuari",
        isFocused: true
    };

    return <div className="flex flex-col" >
        <Label value="Usuari" />
        <UsuariField {...usuari} />
    </div >
}

export default Usuari