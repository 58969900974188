import React from 'react';
import './PedirPieza.module.css';
import { Container } from './Container';

const PedirPieza = () => {
  const triggerText = 'Pedir Pieza';
  const onSubmit = (event) => {
    event.preventDefault(event);   
  };
  return (
    <div>
      <Container triggerText={triggerText} onSubmit={onSubmit} />
    </div>
  );
};

export default PedirPieza;