import React from "react"
import { Link } from "react-router-dom"
import { HOME } from "../../../constants/routes"


const Logo = () => (
    <Link to={HOME.link} className="font-bold text-white text-2xl ">        
        
    </Link>)

export default Logo