import React, {useState, useEffect, Fragment} from 'react'
import styles from './Aviso.module.css'
import { Button } from "@chakra-ui/button"
import { MdMap } from "react-icons/md"
import TabNav from '../TabNav';
import Tab from '../Tab';
import axios from 'axios'
import Historico from "../Historico"
import SmartTable from "react-next-table";
import BarcodeScanner from '../BarcodeReader';
import HeadCellsHistory from './HeadCellsHistory.json';
import HeadCellsPiezas from './HeadCellsPiezas.json';
import PedirPieza from '../PedirPieza';
import Cierre from '../Cierre';
import Piezas from '../Piezas';
import Situacion from '../Situacion';
import {useNavigate } from 'react-router-dom'
import { MdNavigateBefore } from "react-icons/md"

const Aviso = () => {

    const [selected, setSelected] = useState('Datos')
    const [historial, setHistorial] = useState([])
    const [piezas, setPiezas] = useState([])
    const [piezasgarantia, setPiezasGarantia] = useState([])
    const [titleHistorico, setTitleHistorico] = useState('')
    const [contadores, setContadores] = useState([])

    const aviso = JSON.parse(sessionStorage.getItem('aviso'));
    const navigate = useNavigate();


const goto = () => {
    setTimeout(navigate("/Avisos"),1000); 
}


    /*
    useEffect(()=>{
    if(aviso&&aviso.NUMSERIE!=''){

        //let historicourl =  process.env.REACT_APP_API_BASEURL + '/api/historico?numserie=' + aviso.NUMSERIE + '&cliente=' + aviso.CONTRATO;
        let piezasurl =  process.env.REACT_APP_API_BASEURL + '/api/piezas?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO;
        let piezasgarantiaurl = process.env.REACT_APP_API_BASEURL + '/api/piezasgarantia?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO;
        let contadoresurl = process.env.REACT_APP_API_BASEURL + '/api/contadores?numserie=' + aviso.NUMSERIE;
    


        //const requestHistorico = axios.get(historicourl);
        const requestPiezas = axios.get(piezasurl);
        const requestPiezasGarantia = axios.get(piezasgarantiaurl);
        const requestContadores = axios.get(contadoresurl);

        axios.all([requestPiezas, requestPiezasGarantia,requestContadores]).then(axios.spread((...responses) => {
            //const responseHistorico = responses[0]
            const responsePiezas = responses[0]
            const responesPiezasGarantia = responses[1]    
            const responesContadores = responses[2]    
    /*
            const hist = responseHistorico.data;
            setHistorial(hist)

            if (aviso.NUMSERIE==='MANUAL')
            setTitleHistorico(aviso.NOMBRE)
            else
            setTitleHistorico(aviso.NUMSERIE)


            const piez = responsePiezas.data;                
            setPiezas(piez)

            const piezgar = responesPiezasGarantia.data;                
            setPiezasGarantia(piezgar)

            const cont = responesContadores.data;            
            setContadores(cont);


console.log(piezas);


        })).catch(errors => {
            // react on errors.
        })
    }
        
},[])
*/
/*
    useEffect(()=>{
        if(aviso&&aviso.NUMSERIE!=''){
              
              const url = process.env.REACT_APP_API_BASEURL + '/api/historico?numserie=' + aviso.NUMSERIE + '&cliente=' + aviso.CONTRATO
              axios.get(url)
              .then(res => {        
                const hist = res.data;
                setHistorial(hist)
              })

              if (aviso.NUMSERIE==='MANUAL')
                setTitleHistorico(aviso.NOMBRE)
              else
                setTitleHistorico(aviso.NUMSERIE)
            }        
    },[])

    useEffect(()=>{
        if(aviso&&aviso.NUMSERIE!=''){
              
              const url = process.env.REACT_APP_API_BASEURL + '/api/piezas?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO
              axios.get(url)
              .then(res => {        
                const piez = res.data;                
                setPiezas(piez)
              })              
            }  
      
    },[])

    useEffect(()=>{
        if(aviso&&aviso.NUMSERIE!=''){
              
              const url = process.env.REACT_APP_API_BASEURL + '/api/piezasgarantia?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO
              axios.get(url)
              .then(res => {        
                const piez = res.data;                
                setPiezasGarantia(piez)
              })              
            }  
      
    },[])



    useEffect(() => {
        if(aviso&&aviso.NUMSERIE!=''){
              
            const url = process.env.REACT_APP_API_BASEURL + '/api/contadores?numserie=' + aviso.NUMSERIE;
            axios.get(url)
            .then(res => {
                const cont = res.data;            
                setContadores(cont);
                
            })
        }   
      },[]);

*/
    const handleClickMapa=()=>{
        window.open("https://maps.google.com?q="+aviso.POSITION.lat+","+aviso.POSITION.lng );
    }

    const renderNavButton = () => {
        if (aviso.KM != "0") {
          return <div className={styles.map} >
                    <Button leftIcon={<MdMap />} variant='outline' className={styles.button} onClick={handleClickMapa}>
                    </Button>     
                </div>
        } else {
          return <div>&nbsp;</div>;
        }
      }

    return(
        <div className={styles.card}><div className={styles.goto}><Button leftIcon={<MdNavigateBefore />} onClick={goto} colorScheme='blue' > Volver</Button></div>
            <div className={styles.tabs}>
                <TabNav tabs={['Datos', 'Historial', 'Piezas', 'Cierre', 'Situación']} selected={ selected } setSelected={ setSelected } >
                <Tab isSelected={ selected === 'Datos' }>
                    <p>                                    
                            <div className={styles.footer}>

                                <div>
                                    <u>Aviso:</u>&nbsp;{aviso.CODIGOAVISO}
                                </div>
                                <div>
                                    <u>Fecha:</u>&nbsp;{aviso.FECHAAVISO}&nbsp;{aviso.HORAAVISO}
                                </div>
                                <div>
                                    <u>N.Serie:</u>&nbsp;{aviso.NUMSERIE}
                                </div>
                                <div>
                                    <u>Descripción:</u>&nbsp;{aviso.NOMBREART}
                                </div>
                                <div>
                                    <u>Fecha Venta:</u>&nbsp;{aviso.FECHAFRADO}
                                </div>
                                { aviso.ORIGEN != aviso.NOMBRE? 
                                   
                                   <div>
                                       <div>
                                            <u>Origen:</u><b>&nbsp;{aviso.ORIGEN}</b>
                                        </div>
                                    </div> : null
                                
                                }  
                                <div>
                                    <u>Cliente:</u>&nbsp;{aviso.NOMBRE}
                                </div>
                                <div>
                                    <u> Dirección:</u>&nbsp;{aviso.DIRECCION}&nbsp;{aviso.POBLACION}&nbsp;{aviso.PROVINCIA}&nbsp;-&nbsp;{aviso.TELEFONO}
                                </div>
                                <div>
                                    <u> Horario:</u>&nbsp;{aviso.HORARIO}
                                </div>
                                <div>
                                    <div className={styles.km}><u>Distancia:</u>&nbsp;{aviso.KM}&nbsp;Km </div>
                                    {renderNavButton()}
                                </div>  
                                <div  className={styles.incidencia}>
                                    <u>Situación:</u>&nbsp;{sessionStorage.getItem('situacion')!=null? sessionStorage.getItem('situacion'): aviso.SITUACION}
                                </div>                   
                                <div  className={styles.incidencia}>
                                    <u>Incidencia:</u>&nbsp;{aviso.INCIDENCIA}
                                </div>            
                            </div>        
                       
                    </p>
                </Tab>
                <Tab isSelected={ selected === 'Historial' }>
                    <div className={styles.footer}>                        
                        {
                               <Historico aviso={aviso}></Historico>
                        }
                    </div>
                </Tab>
                <Tab isSelected={ selected === 'Piezas' }>
                <div className={styles.footer}>                        
                    <Piezas/>
                    <div className={styles.map} >
                        <PedirPieza></PedirPieza>                        
                    </div>
                    </div>
                </Tab>
                <Tab isSelected={ selected === 'Cierre' }>
                <div className={styles.footer}>               
                        <Cierre aviso={aviso} contadores={contadores} piezas={piezasgarantia}/>                        
                    </div>
                </Tab>
                <Tab isSelected={ selected === 'Situación' }>
                <div className={styles.footer}>               
                        <Situacion aviso={aviso}/>                        
                    </div>
                </Tab>
                </TabNav>
            </div>
        </div>





        
    )
}

export default Aviso
