import React, { useState } from "react";
//import Scanner from "./Scanner";
import ReactDOM from "react-dom";
import PedirPieza from '../PedirPieza';
import Scanner from "react-qr-barcode-scanner";
import "./styles.css";

const BarcodeScanner = props => {
  
  const [camera, setCamera] = useState(false);
  const [result, setResult] = useState(null);


  const onDetected = result => {
    setResult(result);     
    props.onDetected(result);   
    //resetResult("");
    setCamera(false);
  };
  const resetResult = result => {   
    props.resetResult(result);   
  };

  
  return (
    <div style={{marginTop:"10px"}}>
            
      <button type="button" onClick={() => {setCamera(!camera); !camera? resetResult(""):resetResult(result);}}>
        {camera ? "Parar" : "Escanear"}
      </button>
      <div className="container" style={{marginTop:"10px"}}>
        {camera && <Scanner onUpdate={(err, result) => {
          if (result) onDetected(result.text);}} width="500px"/>}
      </div>
    </div>
  );
}

export default BarcodeScanner;
