import React, {useState, useEffect} from 'react'
import styles from './Piezas.module.css'
import { Button } from "@chakra-ui/button"
import { MdMap } from "react-icons/md"
import { NavLink,Link } from "react-router-dom"
import * as ROUTES from "../../../constants/routes"
import axios from 'axios'
import SmartTable from "react-next-table";
import HeadCellsPiezas from './HeadCellsPiezas.json';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


export const Piezas = () => {
    const [piezas, setPiezas] = useState([])

    useEffect(()=>{
            const aviso = JSON.parse(sessionStorage.getItem('aviso'));
       
              const url = process.env.REACT_APP_API_BASEURL + '/api/piezas?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO
              axios.get(url)
              .then(res => {        
                const piez = res.data;                
                setPiezas(piez)
              })    
    },[])
    return(
      <div>
      
        <div className="ag-theme-alpine" style={{height: 350, width: 1150, marginTop:10, marginBottom:10}}>   
            
                 
            <AgGridReact
               rowData={piezas}
               columnDefs={HeadCellsPiezas}>
           </AgGridReact>
        </div>
        </div>
    )
}
export default Piezas
