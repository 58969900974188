import React, { useState, useEffect, useRef,useCallback } from "react";
import axios from "axios"
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './FormSituacion.module.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useNavigate } from 'react-router-dom'
import { experimental_sx } from "@mui/material";
import { Button } from "@chakra-ui/button"
import { MdDone,MdCheckCircle } from "react-icons/md"
import { blue } from "@mui/material/colors";
import * as ROUTES from "../../../../constants/routes"


export const FormSituacion = ({aviso}) => {
 
  const [value, setValue] = useState(aviso.SITUACION);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    let sendform = true;
    const valorsituacion = event.target["situacion"].value;
    if(aviso.SITUACION!=value){
      sendForm(event);     
    } 
  };
 
  useEffect(()=>{
    if (sessionStorage.getItem('situacion')!='null'){
      console.log("Situacion Storage: " + sessionStorage.getItem('situacion'));
      setValue(sessionStorage.getItem('situacion'));
    }
},[])


  

  const sendForm=(event)=>{

    const avi = JSON.parse(sessionStorage.getItem('aviso'));
    const tec = JSON.parse(sessionStorage.getItem('tecnic'));
    const usuario = tec.NomTecnico;
    const aviso = avi.CODIGOAVISO;
    

    navigator.geolocation.getCurrentPosition(
      function(position) {
      sessionStorage.setItem('latitude',JSON.stringify(position.coords.latitude))    
      sessionStorage.setItem('longitude',JSON.stringify(position.coords.longitude))    
    });

    
   

    
    if(aviso.SITUACION!=value){
       //actualizamos aviso      
       axios.get(process.env.REACT_APP_API_BASEURL + '/api/situacion?aviso=' + aviso + '&idtec=' + tec.IdTecnico + '&situacion=' + value + '&latitude=' + sessionStorage.getItem('latitude')+ '&longitude=' + sessionStorage.getItem('longitude'))
       .then(res => {
          const responseSituacion = res.data;   
          sessionStorage.removeItem('situacion')    
          sessionStorage.setItem('situacion', value)    
          alert("Se ha actualizado Situación!!")  
        })//si OK Aviso                                
    };   
 
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
        <div style={{marginBottom:10}}>
          <Stack spacing={2} sx={{ width: 300 }}>
            <TextField label="Editar Situación" id="situacion" type="text" multiline rows={10} value={value} onChange={handleChange}/>
          </Stack>  
                     
        </div>        
        <div className="form-group">
        <button style={{ width:100}} className="form-control btn btn-primary" type="submit">
          Aceptar
        </button>
      </div>
    </form>    
  );
};
export default FormSituacion;