import React from "react"
import {Routes, Route } from "react-router-dom"
import Home, {
    LoginPage,
    AvisosPage,
    AvisosDistance,
    MapsPage,
    AvisoPage
} from "../../../pages"

const Switcher = () => (
    
        <Routes>       
            <Route path="/avisos" element={<AvisosPage/>}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/avisosdistance" element={<AvisosDistance/>}></Route>
            <Route path="/map" element={<MapsPage />}></Route>
            <Route path="/aviso" element={<AvisoPage />}></Route>
        </Routes>
    )

export default Switcher