import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
 

const Map = () => {
  
  const [activeMarker, setActiveMarker] = useState(null);
  const google = window.google;
  const tecnic = JSON.parse(sessionStorage.getItem('tecnic'));
  const markers = JSON.parse(sessionStorage.getItem('avisos'));

  const currentLat = sessionStorage.getItem('latitude');    
  const currentLng = sessionStorage.getItem('longitude');   



  



  const currentPosition ={
    lat:parseFloat(currentLat),
    lng:parseFloat(currentLng)
  }
  const svgMarker = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    //path: "M-20, 0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(15, 30),
  };
  
  
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({POSITION}) => bounds.extend(POSITION));
    map.fitBounds(bounds);
    setActiveMarker(200)
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "95vh"}}
    >
      {markers.map(({ CODIGOAVISO, NOMBRE, POSITION }) => (
        <Marker
          key={parseInt(CODIGOAVISO)}
          position={POSITION}
          onClick={() => handleActiveMarker(parseInt(CODIGOAVISO))}
        >
          {activeMarker === parseInt(CODIGOAVISO) ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{NOMBRE}</div>
            </InfoWindow>
          ) : null}
        </Marker>

      ))}
        <Marker
          key={200}
          position={currentPosition}
          onClick={() => handleActiveMarker(200)}
          icon={svgMarker}
        >
          {activeMarker === 200 ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{"ESTÁS AQUÍ"}</div>
            </InfoWindow>
          ) : null}
        </Marker>
    </GoogleMap>
  );
}

export default Map;