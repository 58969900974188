import React from 'react';
import FormSituacion from './FormSituacion';

const Situacion = (props) => {
  const onSubmit = (event) => {
    event.preventDefault(event);   
  };
  return (
    <div>        
      <FormSituacion aviso={props.aviso} />
    </div>
  );
};

export default Situacion;