import React, { useEffect} from "react";
import AvisosForm from "./AvisosDistance";


export default function Avisos() {
  

  useEffect(()=>{    
    const tecnic = JSON.parse(sessionStorage.getItem('tecnic'));
      if(!tecnic){
        window.location = './';
      }
  },[])
    
    return <AvisosForm />;
  }