import React, {useState, useEffect} from 'react'
import styles from './Historico.module.css'
import { Button } from "@chakra-ui/button"
import { MdMap } from "react-icons/md"
import { NavLink,Link } from "react-router-dom"
import * as ROUTES from "../../../constants/routes"
import axios from 'axios'
import SmartTable from "react-next-table";
import HeadCellsHistory from '../Aviso/HeadCellsHistory.json';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


export const Historico = () => {
    const [historial, setHistorial] = useState([])
    const [titleHistorico, setTitleHistorico] = useState('')

    
    const [columnDefsHistorial] = useState([
      { field: "CODIGOAVISO", headerName: "Aviso", width: 100},
      { field: "FECHAAVISO", headerName: "Fecha", width: 110, wrapText: true, autoHeight: true },
      { field: "NUMSERIE", headerName: "Nº. Serie", editable: true, width: 120 },
      { field: "REPARACION", headerName: "Reparación", editable: true, width: 480 },
      { field: "INCIDENCIA", headerName: "Incidencia", editable: true, width: 480 },
      { field: "TECNICO", headerName: "Técnico", editable: true, width: 200 },
  ]);   
    
 


    useEffect(()=>{
            const aviso = JSON.parse(sessionStorage.getItem('aviso'));
       
              const url = process.env.REACT_APP_API_BASEURL + '/api/historico?numserie=' + aviso.NUMSERIE + '&cliente=' + aviso.CONTRATO
              axios.get(url)
              .then(res => {        
                const hist = res.data;
                setHistorial(hist)
              })

              if (aviso.NUMSERIE==='MANUAL')
                setTitleHistorico(aviso.NOMBRE)
              else
               setTitleHistorico(aviso.NUMSERIE)
               
    },[])

    return(
      <div>
      {titleHistorico}    
        <div className="ag-theme-alpine" style={{height: 500, width: 1500, marginTop:10}}>   
            
                 
            <AgGridReact
               rowData={historial}
               columnDefs={columnDefsHistorial}>
           </AgGridReact>
        </div>
        </div>
    )
}

export default Historico
