import React, { useState, useEffect, useRef,useCallback } from "react";
import axios from "axios"
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './FormCierre.module.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useNavigate } from 'react-router-dom'
import { experimental_sx } from "@mui/material";
import { Button } from "@chakra-ui/button"
import { MdDone,MdCheckCircle } from "react-icons/md"
import { blue } from "@mui/material/colors";




export const FormCierre = (props) => {
 
  /*Contadores */
  const gridContRef = useRef();
  const [contadores, setContadores] = useState([])
  /*Garantias */
  const gridGarRef = useRef();
  const [piezasgarantia, setPiezasGarantia] = useState([])

  const [insertAviso, setInsertAviso] = useState(false);
  const [insertCont, setInsertCont] = useState(false);
  const [insertGar, setInsertGar] = useState(false);
  const [error, setError] = useState(false);
  const [validado, setValidado] = useState(false);
  const [edited, setEdited] = useState(false);
  const navigate = useNavigate();



 
    const handleSubmit = (event) => {
      event.preventDefault();
      let valor = "";
      let valorcontador = "";
      let mayorcontador = true;
      let sendform = true;
      if(gridGarRef!=null){
        //actualizamos garantias                       
        gridGarRef.current.api.forEachNodeAfterFilter((rowNode, index) => {
          if(rowNode.data.SERIENUEVO!=""){
            valor = rowNode.data.SERIENUEVO;
          }
        });
      }

      if(gridContRef!=null){
        //actualizamos garantias                       
        gridContRef.current.api.forEachNodeAfterFilter((rowNode, index) => {
          if(rowNode.data.nuevo!="" && rowNode.data.nuevo!="0"){
            valorcontador = rowNode.data.nuevo;
            if (rowNode.data.nuevo<=rowNode.data.contador){
              alert("El Nuevo Contador debe ser superior al Actual");
              mayorcontador = false;
            }
          }
          
        });
      }      
      
      if(edited==true && validado==false){
        alert("Tienes que validar los cambios");
        sendform=false;
      }
        

      if(validado && (valor!="" && gridGarRef!=null) && sendform)
        sendForm(event);
      else if ((validado && (valorcontador!="" && gridContRef!=null) && mayorcontador==true) && sendform)
        sendForm(event);
      else if (((valor=="" && gridGarRef!=null) && (valorcontador=="" && gridContRef!=null)) && sendform)
        sendForm(event);
      else if ((gridGarRef==null && gridContRef==null) && sendform)
        sendForm(event);

    }
 
  const sendForm=(event)=>{

    const avi = JSON.parse(sessionStorage.getItem('aviso'));
    const tec = JSON.parse(sessionStorage.getItem('tecnic'));
    const usuario = tec.NomTecnico;
    const aviso = avi.CODIGOAVISO;

    navigator.geolocation.getCurrentPosition(
      function(position) {
      sessionStorage.setItem('latitude',JSON.stringify(position.coords.latitude))    
      sessionStorage.setItem('longitude',JSON.stringify(position.coords.longitude))    
    });

    const counterObject = 
    { REFERENCIA: "", CODCLIENTE: "", CONTADOR1: "0", CONTADOR2: "0", CONTADOR3: "0", CONTADOR4: "0", CONTADOR5: "0", CONTADOR6: "0",
      NAMECONT1:"",NAMECONT2:"",NAMECONT3:"",NAMECONT4:"",NAMECONT5:"",NAMECONT6:"", AVISO:""}; 

    counterObject.REFERENCIA = avi.REFERENCIA;
    counterObject.AVISO = avi.CODIGOAVISO;
    counterObject.CODCLIENTE = avi.CONTRATO;
    let okcontador = true;

    const hora = event.target["horainicio"].value;
    const reparacion = event.target["reparacion"].value;

    
    
    if(hora!="" && reparacion!=""){
      if(gridContRef!=null){ 
        gridContRef.current.api.forEachNodeAfterFilter((rowNode, index) => {
          if(rowNode.data.nuevo>0){
           
            
            switch(index){
              case 0:
                counterObject.CONTADOR1=rowNode.data.nuevo;
                break;
              case 1:
                counterObject.CONTADOR2=rowNode.data.nuevo;
                break;
              case 2:
                counterObject.CONTADOR3=rowNode.data.nuevo;
                break;
              case 3:
                counterObject.CONTADOR4=rowNode.data.nuevo;
                break;
              case 4:
                counterObject.CONTADOR5=rowNode.data.nuevo;
                break;
              case 5:
                counterObject.CONTADOR6=rowNode.data.nuevo;
                break;
            }        
          }
        });
      }
       //actualizamos aviso      
       axios.get(process.env.REACT_APP_API_BASEURL + '/api/avisos?aviso=' + aviso + '&idtec=' + tec.IdTecnico + '&reparacion=' + reparacion + '&horainicio=' + hora + '&latitude=' + sessionStorage.getItem('latitude')+ '&longitude=' + sessionStorage.getItem('longitude'))
       .then(res => {
          const responseAviso = res.data;
          setInsertAviso(responseAviso);
          setError(responseAviso);
          if (responseAviso){
              //insertamos contadores
              if(counterObject.CONTADOR1!="0")
              {
                axios.get(process.env.REACT_APP_API_BASEURL + '/api/contadores?aviso=' + counterObject.AVISO + '&contador1=' + counterObject.CONTADOR1 + '&contador2=' + counterObject.CONTADOR2 + '&contador3=' + counterObject.CONTADOR3 + '&contador4=' + counterObject.CONTADOR4 + '&contador5=' + counterObject.CONTADOR5 + '&contador6=' + counterObject.CONTADOR6
                    ).then(res => {
                      const responseCont = res.data;
                      setInsertCont(responseCont);
                      setError(responseCont);
                      if (!responseCont){
                        alert("Se ha producido un error al insertar el contador")
                      }
                      if(gridGarRef!=null){
                        //actualizamos garantias                       
                        gridGarRef.current.api.forEachNodeAfterFilter((rowNode, index) => {
                          if(rowNode.data.SERIEANT!="" && rowNode.data.CODIGO!=""){
                            axios.get(process.env.REACT_APP_API_BASEURL + '/api/PiezasGarantia?usuario=' + usuario + '&aviso=' + aviso + '&serieant=' + rowNode.data.SERIEANT + '&serienew=' + rowNode.data.SERIENUEVO+ '&codigo=' + rowNode.data.CODIGO)
                              .then(res => {
                                const responseGar = res.data;
                                setInsertGar(responseGar);
                                setError(responseGar);
                                if (!responseGar){
                                  alert("Se ha producido un error al actualizar Garantias")
                                }                                
                            })
                          }
                        });  
                        setTimeout(
                        axios.get(process.env.REACT_APP_API_BASEURL + '/api/mail?usuario=' + usuario + '&aviso=' + aviso)
                            .then(res => {
                              const responseMail = res.data;
                              if (!responseMail){
                                alert("Se ha producido un error al enviar el mail de notificación");                         
                              }
                              else{
                                setTimeout(navigate("/Avisos"),1000);
                              }
                          }),30000);
                      }else{
                        axios.get(process.env.REACT_APP_API_BASEURL + '/api/mail?usuario=' + usuario + '&aviso=' + aviso)
                        .then(res => {

                          const responseMail = res.data;
                          if (!responseMail){
                            alert("Se ha producido un error al enviar el mail de notificación");                         
                          }
                          else{
                            setTimeout(navigate("/Avisos"),1000);
                          }
                        });  
                      }
                    }
                  )
              }//if(counterObject.CONTADOR1!="0")
              else
              {
                if(gridGarRef!=null)
                {
                  //actualizamos garantias
                  gridGarRef.current.api.forEachNodeAfterFilter((rowNode, index) => {
                  if(rowNode.data.SERIEANT!="" && rowNode.data.CODIGO!=""){
                      axios.get(process.env.REACT_APP_API_BASEURL + '/api/PiezasGarantia?usuario=' + usuario + '&aviso=' + aviso + '&serieant=' + rowNode.data.SERIEANT + '&serienew=' + rowNode.data.SERIENUEVO+ '&codigo=' + rowNode.data.CODIGO)
                        .then(res => {
                          const response = res.data;
                          setInsertGar(response);
                          setError(response);
                          if (!response){
                            alert("Se ha producido un error al actualizar Garantias")
                          }                 
                      });
                    }
                  });
                  //esperamos 
                  setTimeout(
                    axios.get(process.env.REACT_APP_API_BASEURL + '/api/mail?usuario=' + usuario + '&aviso=' + aviso)
                        .then(res => {
                          const responseMail = res.data;
                          if (!responseMail){
                            alert("Se ha producido un error al enviar el mail de notificación");                         
                          }
                          else{
                            setTimeout(navigate("/Avisos"),1000);
                          }
                      }),5000);
                }//if(gridGarRef!=null) 
                else{
                  axios.get(process.env.REACT_APP_API_BASEURL + '/api/mail?usuario=' + usuario + '&aviso=' + aviso)
                    .then(res => {
                      const responseMail = res.data;
                      if (!responseMail){
                        alert("Se ha producido un error al enviar el mail de notificación");                         
                      }
                      else{
                        setTimeout(navigate("/Avisos"),1000);
                      }
                  });  
                } 
              }
            }//si OK Aviso
          else{
           alert("Se ha producido un error al hacer el precierre del aviso");
          }          
       })//axios.get(aviso)
    }//if(hora!="" && reparacion!="")
    else{
      alert("Los campos Hora Inicio y Reparación son obligatorios");
    }
    
  }  
 

  const [columnDefsContadores] = useState([
      { field: "name", headerName: "Nombre", width: 100},
      { field: "contador", headerName: "Contador", width: 100},
      { field: "nuevo", headerName: "Nuevo", editable: true, width: 100}
  ]);  

  useEffect(()=>{
            const aviso = JSON.parse(sessionStorage.getItem('aviso'));
           

            const url = process.env.REACT_APP_API_BASEURL + '/api/contadores?numserie=' + aviso.NUMSERIE;
            axios.get(url)
            .then(res => {
                const cont = res.data;            
              
              setContadores([
                { name: cont.NAMECONT1, contador: cont.CONTADOR1, nuevo: 0 },
                { name: cont.NAMECONT2, contador: cont.CONTADOR2, nuevo: 0 },
                { name: cont.NAMECONT3, contador: cont.CONTADOR3, nuevo: 0 },
                { name: cont.NAMECONT4, contador: cont.CONTADOR4, nuevo: 0 },
                { name: cont.NAMECONT5, contador: cont.CONTADOR5, nuevo: 0 },
                { name: cont.NAMECONT6, contador: cont.CONTADOR6, nuevo: 0 }
              ]);
            })    


            
          
  },[])


  
  
  const [columnDefsGarantias] = useState([
    { field: "CODIGO", headerName: "Codigo", width: 100},
    { field: "ESTADO", headerName: "Estado", width: 125},
    { field: "REFERENCIA", headerName: "Referencia", width: 125},
    { field: "DESCRIPCION", headerName: "Descripción", width: 200 },
    { field: "SERIEANT", headerName: "Serie Anterior", width: 125, editable:true },
    { field: "SERIENUEVO", headerName: "Serie Nuevo", width: 125, editable:true},
  ]); 

  useEffect(()=>{
  const aviso = JSON.parse(sessionStorage.getItem('aviso'));
  const url = process.env.REACT_APP_API_BASEURL + '/api/piezasgarantia?aviso=' + aviso.CODIGOAVISO + '&cliente=' + aviso.CONTRATO
    axios.get(url)
    .then(res => {        
      const piez = res.data;                
      setPiezasGarantia(piez)
    })          
  },[])

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridGarRef.current.api.getSelectedRows();
      alert(selectedRows.length === 1 ? selectedRows[0].CODIGO : '');
  }, []);

  const onCellEditingStarted  = useCallback((event) => {
   setEdited(true);
  }, []);

  const onBtStopEditing = () => {
    if(gridGarRef!=null){
      gridGarRef.current.api.stopEditing();
      setValidado(true);
    }
    if(gridContRef!=null){
      gridContRef.current.api.stopEditing();
      setValidado(true);
    }

  };

  const onBtPreviousCell = useCallback(() => {
    gridGarRef.current.api.tabToPreviousCell();
  }, []);
  return (
    <form onSubmit={handleSubmit}>
         
        <div style={{marginBottom:10}}>
          <Stack spacing={2} sx={{ width: 300 }}>
            <TextField label="Hora Inicio" id="horainicio" type="time" />
            <TextField label="Reparación Efectuada" id="reparacion" type="string" multiline rows={10} />
          </Stack>  
                     
        </div>
        <div>
          Contadores
       </div>
        <div className="ag-theme-alpine" style={{height: 350, width: 313, marginTop:10, marginBottom:10}}>
        <div className={styles.done} style={{height: 20, width: 1, marginLeft:10, marginBottom:10, float:"right",fontSize:16, color:blue}}>  
            <Button rightIcon={<MdCheckCircle />} onClick={onBtStopEditing} colorScheme='red' > Validar</Button>
          </div>
            <AgGridReact style={{backgroundColor:'#ccc'}}
               ref={gridContRef}
               rowData={contadores}
               columnDefs={columnDefsContadores}
               onCellEditingStarted ={onCellEditingStarted }
               >
           </AgGridReact>
       </div>
       <div className={styles.done} >Garantias
          
       </div>
       <div className="ag-theme-alpine" style={{height: 250, width: 820, marginTop:10, marginBottom:10}}>
          <div className={styles.done} style={{height: 20, width: 1, marginLeft:10, marginBottom:10, float:"right",fontSize:16, color:blue}}>  
            <Button rightIcon={<MdCheckCircle />} onClick={onBtStopEditing} colorScheme='red' > Validar</Button>
          </div>
            <AgGridReact
               ref={gridGarRef}
               rowData={piezasgarantia}
               columnDefs={columnDefsGarantias}              
               onCellEditingStarted ={onCellEditingStarted }
               >
           </AgGridReact> 
           </div>
        <div className="form-group">
        <button style={{ width:200}}  className="form-control btn btn-primary" type="submit">
          Pre-Cierre
        </button>
      </div>
    </form>    
  );
};
export default FormCierre;