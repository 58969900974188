import React, { useState, useEffect} from "react";
import { useLoadScript } from "@react-google-maps/api";
import Map from "./Maps";


export default function Maps() {
  const markers = JSON.parse(sessionStorage.getItem('avisos'));

  useEffect(()=>{          
      if(!markers)
        window.location = './';
  },[])
    

    const { isLoaded } = useLoadScript({
      //googleMapsApiKey: "" // Add your API key
      googleMapsApiKey: "AIzaSyBSkouqFxOTuY-iM3Je39UNifqqUiD0AYc" // Add your API key
    });
  
    return isLoaded ? <Map /> : null;
  }