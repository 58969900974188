import React, { useState, useEffect} from "react";
import { useLoadScript } from "@react-google-maps/api";
import AvisosForm from "./Avisos";


export default function Avisos() {
  const tecnic = JSON.parse(sessionStorage.getItem('tecnic'));

  useEffect(()=>{    
      if(!tecnic)
        window.location = './';
  },[])
    
    return <AvisosForm />;
  }