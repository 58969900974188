import React from 'react';
import FormCierre from './FormCierre';

const Cierre = (props) => {
  const onSubmit = (event) => {
    event.preventDefault(event);   
  };
  return (
    <div>        
      <FormCierre aviso={props.aviso} contadores={props.contadores} piezas={props.piezas} onSubmit={onSubmit} />
    </div>
  );
};

export default Cierre;