import React from 'react';
import Switcher from './Switcher';
import Linker from "./Linker"

const Navigation = () => {
        
    return(
    <div>
        <Linker></Linker>
    </div>
    )

}

export default Navigation
export { Switcher }