import React from "react"
import {useNavigate} from "react-router-dom"
import {HOME} from "../../../constants/routes"

const Search = () => {    
    const navigate =useNavigate()
    const onSearch=()=>{
        navigate(HOME.link)
    }    
    return <form className="mr-32 w-1/6
                            xs:text-base xs:ml-2 xs:mr-1 xs:w-full
                            sm:text-base sm:ml-3 sm:mr-2 sm:w-2/5 
                            md:text-base md:ml-3 md:mr-2 md:w-2/5 
                            lg:text-base lg:ml-4 md:mr-4 lg:w-1/4"  
                            onSubmit={onSearch} >
       
    </form>
}

export default Search