import React,{useEffect, useState} from "react"
import * as ROUTES from "../../../constants/routes"
import { NavLink,Link} from "react-router-dom"





const handleClickMap = () =>{
    
    navigator.geolocation.getCurrentPosition(
        function(position) {
        //console.log("Latitude is :", position.coords.latitude);
        //console.log("Longitude is :", position.coords.longitude);
        sessionStorage.setItem('latitude',JSON.stringify(position.coords.latitude))    
        sessionStorage.setItem('longitude',JSON.stringify(position.coords.longitude))    
        });
}

const handleAvisosDistance = (ordertype) =>{   
    handleClickMap()
    sessionStorage.setItem('ordertype', 'distance');    
    //alert(ordertype);
    //window.location.reload();
}
const handleAvisosNumber = (ordertype) =>{   
    handleClickMap()
    sessionStorage.setItem('ordertype', 'number');    
    //alert(ordertype);

    //window.location.reload();
}





const Linker = () => {
    /*const state = {
        urlAvisos:ROUTES.AVISOS.link,
        urlAvisosDistance:ROUTES.AVISOSDISTANCE.link,
        urlMaps:ROUTES.MAPA.link
    }
    const tecnic = JSON.parse(sessionStorage.getItem('tecnic'))

    if (!tecnic){
        state.urlAvisos=ROUTES.LOGIN.link
        state.urlAvisosDistance=ROUTES.LOGIN.link
        state.urlMaps=ROUTES.LOGIN.link        
    }*/

    

    return(
  
    <nav>
        <ul className="flex text-white font-helvetica tracking-wide text-base xs:text-xs sm:text-sm md:text-sm justify-between xs:px-6">
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4" onClick={handleClickMap}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4" onClick={handleClickMap}><NavLink to={ROUTES.MAPA.link} className="cursor-pointer text-orange-400" >{ROUTES.MAPA.name}</NavLink></li>
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4" onClick={()=>handleAvisosNumber("number")} ordertype="number"><NavLink to={ROUTES.AVISOS.link} className="cursor-pointer text-orange-400" >Número</NavLink></li>
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4" onClick={()=>handleAvisosDistance("distance")} ordertype="distance"><NavLink to={ROUTES.AVISOSDISTANCE.link} className="cursor-pointer text-orange-400" >Distancia</NavLink></li>
        </ul>
    </nav>
    )
}
export default Linker