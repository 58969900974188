import React, { useState, useEffect } from "react";
import BarcodeScanner from '../../BarcodeReader';
import axios from "axios"
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
//import Autocomplete from '@mui/material/Autocomplete';
//import Autocomplete from '../AutoComplete';
import '../PedirPieza.module.css'

export const Form = () => {
  
  const [result, setResult] = useState(null);
  const [descrip, setDescrip] = useState("");
  const [referencia, setRefer] = useState("");
  const [articulos, setArticulos] = useState([]);
  const [observaciones, setObservaciones] = useState(""); 
  const [cantidad, setCantidad] = useState(0);

  const onBlur = (e, label) => {
    const userInput = e.target.innerText;
    if(label==="Referencia"){
      setReferencia(userInput);      
    }
    else{
      setDescripcion(userInput);
    }
  };
  
  useEffect(() => {
    const art = JSON.parse(sessionStorage.getItem('articulos'));
    setArticulos(art);
  },[]);


  const handleChangeCantidad = (event) => {
    setCantidad(event.target.value);
  };

  const handleChangeObservaciones = (event) => {
    setObservaciones(event.target.value);
  };

const handleChangeCheck = (event) =>{
  if (event.target.checked)
    {
      setObservaciones('En Stock');
    }
    else{
      setObservaciones('');
    }
};

  const onDetected = result => {
    setRefer(result);
    setReferencia(result);     
  };

  const resetResult = result => {         
    setRefer(result); 
  };

  

  const setReferencia = (ref) => {
    setRefer(ref);
    articulos.map((option) => option.REFERENCIA==ref? setDescrip(option.DESCRIPCION):null)

  }

  const setDescripcion = (desc) => {
    setDescrip(desc);
    articulos.map((option) => option.DESCRIPCION==desc? setRefer(option.REFERENCIA):null)

  }
  

  const handleSubmit = (event) => {
    event.preventDefault()
    const avi = JSON.parse(sessionStorage.getItem('aviso'));
    const tec = JSON.parse(sessionStorage.getItem('tecnic'));
    //const referencia = event.target[0].value;
    //const cantidad = event.target[1].value;               
    //const observaciones = event.target[2].value; 
    const usuario = tec.NomTecnico;
    const aviso = avi.CODIGOAVISO;

    navigator.geolocation.getCurrentPosition(
      function(position) {
      //console.log("Latitude is :", position.coords.latitude);
      //console.log("Longitude is :", position.coords.longitude);
      sessionStorage.setItem('latitude',JSON.stringify(position.coords.latitude))    
      sessionStorage.setItem('longitude',JSON.stringify(position.coords.longitude))    
      });


    const pieza = {usuario: usuario , aviso: aviso, referencia: referencia, cantidad: cantidad, observaciones:observaciones, descripcion:descrip}

    
    if (referencia==='' || cantidad===''|| cantidad==='0'){
      alert("Los campos Referencia y Cantidad son obligatorios")          
    }
    else{    

      axios.get(process.env.REACT_APP_API_BASEURL + '/api/piezas?usuario=' + pieza.usuario + '&aviso=' + pieza.aviso + '&referencia=' + pieza.referencia + '&cantidad=' + pieza.cantidad + '&observaciones=' + pieza.observaciones+ '&descripcion=' + pieza.descripcion + "&latitude=" + sessionStorage.getItem('latitude')+ "&longitude=" + sessionStorage.getItem('longitude'))
      .then(res => {
        const response = res.data;
        if(response==1)
          alert("La pieza se ha pedido correctamente");
        else
          alert("SE HA PRODUCIDO ALGUN ERROR AL PEDIR PIEZA!! Consulte con el administrador del sistema.");

      })
    }
  } 


  const AutoComplete =  props  => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");
    const [label, setLabel] = useState("");
    
    useEffect(() => {
      setLabel(props.label);
      setInput(props.value);
    },[]);
  
  
  
    const onChange = (e) => {
      const userInput = e.target.value;
      // Filter our suggestions that don't contain the user's input
      const unLinked = props.suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
  
      setInput(e.target.value);
      setFilteredSuggestions(unLinked);
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);
      //props.onBlur(e, label);
    };
  
    
  
    const onClick = (e) => {
      setFilteredSuggestions([]);
      setInput(e.target.innerText);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      props.onBlur(e, label);
      //props.refreshValues();
    };
    
    const onKeyDown = (e) =>{
      alert(e.keyCode);
      if (e.keyCode==13){
        setInput(e.target.innerText)        
      }
      if (e.keyCode==38){
        setActiveSuggestionIndex(activeSuggestionIndex-1)     
      }
      if (e.keyCode==40){
        setActiveSuggestionIndex(activeSuggestionIndex+1)     
      }
    }
  
    const SuggestionsListComponent = () => {
      return filteredSuggestions.length ? (
        <ul class="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;
            // Flag the active suggestion with a class
            if (index === activeSuggestionIndex) {
              className = "suggestion-active";
            }
            return (
              <li className={className} key={suggestion} onClick={onClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      ) : (
        <div class="no-suggestions">
          <em>No hay resultados</em>
        </div>
      );
    };
  
    
  
  
     return (
      <>
        <TextField
          type="text"
          onChange={onChange}
          //onKeyDown={onKeyDown}        
          value={input}
          label={label}
        />
        {showSuggestions && input && <SuggestionsListComponent />}
      </>
    );
  };


  return (
    <form onSubmit={handleSubmit}>
        <div>
         <Stack spacing={2} sx={{ width: 300 }}>
         <AutoComplete
            id="referencia"
            label="Referencia"
            value={referencia}           
            onBlur={onBlur}
            suggestions={articulos.map((option) => option.REFERENCIA)}
          />
          <TextField label="Cantidad" id="cantidad" defaultValue='1' type="number" onChange={handleChangeCantidad}/>
                    
          <AutoComplete
            id="descripcion"
            label="Descripción"
            value={descrip}
            onBlur={onBlur}          
            suggestions={articulos.map((option) => option.DESCRIPCION)}
          />
          <TextField label="Observaciones" id="observaciones" multiline rows={4}  onChange={handleChangeObservaciones} value={observaciones}/>
          
          <FormControlLabel
            label="En Stock"
            control={
              <Checkbox                
                onChange={handleChangeCheck}
            />
        }
      />
          </Stack>
        </div>
        <div className="form-group" >
        <button className="form-control btn btn-primary" type="submit">
          Pedir
        </button>
      </div>
      <div className="form-group" style={{marginTop:"10px"}}>

        <BarcodeScanner onDetected={onDetected} resetResult={resetResult} ></BarcodeScanner>
      </div>
      
    </form>    
  );
};
export default Form;