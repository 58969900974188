import React, { useEffect } from "react"
import Usuari from "./Usuari"
import Password from "./Password"
import LoginButton from "./Submit"
import axios from "axios"
import {useNavigate } from 'react-router-dom'


function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

const Form = (props) => {    
    const state = {       
        tecnic:{}
    }
    const navigate = useNavigate();

    useEffect(()=>{
        /*navigator.geolocation.getCurrentPosition(
            function(position) {
              console.log("Latitude is :", position.coords.latitude);
              console.log("Longitude is :", position.coords.longitude);
              sessionStorage.setItem('latitude',JSON.stringify(position.coords.latitude))    
              sessionStorage.setItem('longitude',JSON.stringify(position.coords.longitude))    
            });*/
            //console.log("Navigation");
            const watchId = navigator.geolocation.watchPosition(position => {
                const { latitude, longitude } = position.coords;
                // Show a map centered at latitude / longitude.
                //console.log("Navigation; Latitude is :", latitude);
                //console.log("Navigation; Longitude is :", longitude); 
                sessionStorage.setItem('latitude',JSON.stringify(latitude))    
                sessionStorage.setItem('longitude',JSON.stringify(longitude))   
        
              });
    },[])


    const handleSubmit = (event) => {
        event.preventDefault()

        const user = event.target[0].value;
        const pass = event.target[1].value;               
        
        if (user==='' || pass===''){
          alert("Debes rellenar los dos campos")          
        }
        else{

          axios.get(process.env.REACT_APP_API_BASEURL + '/api/articulos')
          .then(res => {
            const articulos = res.data;
            sessionStorage.setItem('articulos',JSON.stringify(articulos));
          })


          axios.get(process.env.REACT_APP_API_BASEURL + '/api/tecnics?user=' + user+ '&pass=' + pass)
          .then(res => {
            const tecnic = res.data;
            state.tecnic = tecnic;          
            sessionStorage.setItem('tecnic',JSON.stringify(tecnic));
            sessionStorage.setItem('orderbynumber','asc');
            sessionStorage.setItem('orderbydistance','asc');
            if(tecnic && tecnic.IdTecnico!='0'){                
                  setTimeout(navigate("/Avisos"),1000);            
            }
            else{
              alert("Este usuario/contraseña no existe")
            }
          })
        }
    }

    const setUser = (props) => {
        alert(this.value)
        state.user= this.value;
    }


    return (<form className="xs:w-11/12 xs:mt-2 xs:pb-4 
                             sm:w-1/2 sm:mt-10 sm:pb-6
                             md:w-2/5 md:mt-12 md:pb-8
                             lg:w-1/3 lg:mt-12 lg:pb-8
                             block-inline bg-gray-100 rounded-lg mx-auto w-1/4 pb-16 px-8 pt-4 mt-24"            
                             onSubmit={handleSubmit}>
        <Usuari />
        <Password />
        <LoginButton />
    </form>
    )
}
export default Form