import React from 'react';
import ReactDOM from 'react-dom';
import { Form } from '../Form';
import FocusTrap from 'focus-trap-react';
import styles from '../PedirPieza.module.css'
export const Modal = ({
  onClickOutside,
  onKeyDown,
  modalRef,
  buttonRef,
  closeModal,
  onSubmit
}) => {
  return ReactDOM.createPortal(
    <FocusTrap>
      <aside
        tag="aside"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        className={styles["modal-cover"]}
        //onClick={onClickOutside}
        onKeyDown={onKeyDown}
      >
        <div className={styles["modal-area"]} ref={modalRef}>
          <button
            ref={buttonRef}
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className={styles["_modal-close"]}
            onClick={closeModal}
          >
            <span id="close-modal" className={styles["_hide-visual"]}>
              Cerrar
            </span>
            <svg className={styles["_modal-close-icon"]} viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          <div className={styles["modal-body"]}>
            <Form onSubmit={onSubmit} />
          </div>
        </div>
      </aside>
    </FocusTrap>,
    document.body
  );
};

export default Modal;
