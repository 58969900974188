import React from "react"
import { Link } from "react-router-dom"
import { BrandButtonDefault } from "../../library/Button"
import { Navigate, useLocation, useNavigate } from 'react-router-dom'


const SignUpButton = () => {

    const navigate = useNavigate();
    const signup = (e) => {
        e.preventDefault()
        sessionStorage.removeItem('tecnic')
        sessionStorage.removeItem('avisos')
        sessionStorage.removeItem('articulos')
        setTimeout(navigate("/login"),1000);
        window.location.reload()
        //navigate("/login");
    }
    return <Link to="/signup" className="mr-2">
        <BrandButtonDefault
            type="button"
            value="Sign up"
            event={signup}
        />
    </Link>}


export default SignUpButton