import React from "react"
import { BrandButtonLarge as SignInButton } from "../../../../library/Button"





const LoginButton = () => {

    

    const handleClick = (e) => {
        
        e.preventDefault()

    }

    return <SignInButton
        type="submit"
        value="Entrar"
        event={handleClick}
        classes="xs:py-3 sm:py-3 md:py-3 xl:py-3 lg:py-3 mt-8" />
}

export default LoginButton