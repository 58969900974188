import React from "react"
import AvisosForm from "../../components/features/AvisosDistance"
const AvisosDistance = () => {  
    
        
        return(
        <div className="xs:pt-8 items-center align-center" >
            <AvisosForm />
        </div>        
    )
}
export default AvisosDistance