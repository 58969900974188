import React, {Component, useState} from 'react'
import axios from 'axios'
import styles from './Avisos.module.css'
import AvisoItem from'../AvisoItem'
import { FiArrowUpCircle, FiArrowDownCircle } from "react-icons/fi"

class Avisos extends Component {
  state = {    
    avisos:[],
    tecnic:{},
    loading:false,
    orderby: 'desc'
  }

  

componentDidMount() {    
  const tecnic = JSON.parse(sessionStorage.getItem('tecnic'));
  let longitude = null
  let latitude = null
  let ordertype = null
  let orderbynumber = '';
  /*if (!tecnic) {    
    window.location = 'http://localhost:3000/login';
  }*/

  //setTimeout(function() {
  longitude = JSON.parse(sessionStorage.getItem('longitude'));
  latitude = JSON.parse(sessionStorage.getItem('latitude'));
  //console.log("AVISOS; Latitude is :", latitude);
  //console.log("AVISOS; Longitude is :", longitude);
  ordertype = sessionStorage.getItem('ordertype'); 
  orderbynumber = sessionStorage.getItem('orderbynumber');
  
  if(orderbynumber=='desc'){
    orderbynumber='asc';
    sessionStorage.removeItem('orderbynumber');
    sessionStorage.setItem('orderbynumber',orderbynumber);
  }else if(orderbynumber=='asc'){
    orderbynumber='desc';
    sessionStorage.removeItem('orderbynumber');
    sessionStorage.setItem('orderbynumber',orderbynumber);
  }
  this.state.orderby = orderbynumber;
  console.log("Tecnico: " + tecnic.IdTecnico);

if(tecnic&&tecnic.IdTecnico>0){
  this.setState({loading:true}) 
    this.setState({tecnic})  
    
    


    const url = process.env.REACT_APP_API_BASEURL + '/api/avisos?anyo=2021&idtec=' + ("00" + tecnic.IdTecnico).slice(-2) + '&cerrados=false&latitude=' + latitude + '&longitude=' + longitude + '&order=' + ordertype + '&orderby=' + orderbynumber
    console.log(url);
    axios.get(url)
    .then(res => {        
      const avisos = res.data;
      sessionStorage.setItem('avisos',JSON.stringify(avisos));
      this.setState({ avisos });
      this.setState({loading:false}) 
    })
  }
  

  


  
  
   
}



ArrowButtonUp(){
  return (
    <button
      onClick={() => window.location.reload(false)}
      style={{ backgroundColor: "transparent", border: "none" }}
      title='de Menor a Mayor'
      >
      <FiArrowUpCircle/>     
    </button>
  );
};


ArrowButtonDown(){
  return (
    <button
      onClick={() => window.location.reload(false)}
      style={{ backgroundColor: "transparent", border: "none" }}
      title='de Mayor a Menor'
      >
      <FiArrowDownCircle/>
    </button>
  );
};



  render () {
    
    const activeClass = styles['card-active']
    const spinnerClass = styles['loader']
    const clases = styles.card + ' ' + activeClass   
    const {loading} = this.state
    
          return (
            <div className={styles.avisosList}>                                   
              <div>
              <div className={styles.order} >Ordenar {this.state.orderby=='desc'? this.ArrowButtonDown():this.ArrowButtonUp()}</div>
                      {
                        loading && (
                          <div className={spinnerClass}></div>
                        )
                      }

                      {
                        
                          this.state.avisos.map((aviso) => {
                              return(
                                  
                                  <AvisoItem aviso={aviso} key={aviso.CODIGOAVISO} ></AvisoItem>
                              )
                          })
                      }                      
               </div>    
          </div>
          )
    
  }
}

export default Avisos
