import React from 'react';
import styles from './TabNav.module.css'
import '../../../../node_modules/bootstrap/scss/_nav.scss'

 
class TabNav extends React.Component {
  render() {
    return (
        <div style={{ width: '100%'}}>
        <ul className="nowrap nav-tabs">
        {
            this.props.tabs.map(tab => {
                        const active = (tab === this.props.selected ? 'active ' : '' );
                return (
                <li className="nav-item" key={ tab }>
                    <a className={"nav-link " + active} onClick={ () => this.props.setSelected(tab) }>
                    { tab }
                    </a>
                </li>
                );
            })
        }
        </ul>
        { this.props.children }
      </div>
    );
  }
}
export default TabNav;