import React, {useState} from 'react'
import styles from './AvisoItem.module.css'
import { Button } from "@chakra-ui/button"
import { MdMap } from "react-icons/md"
import { NavLink,Link } from "react-router-dom"
import * as ROUTES from "../../../constants/routes"




const AvisoItem = ({aviso}) => {

    const [selected, setSelected] = useState('Datos')


    /*useEffect(()=>{
    
        alert("ÇMaps")
        if (!aviso) {    
          window.location = '/login';
        }
      
    })*/
   

    const handleClickMapa=(e)=>{    
        e.stopPropagation();
        console.log("Mapa")
        window.open("https://maps.google.com?q="+aviso.POSITION.lat+","+aviso.POSITION.lng );
    }

    const handleClickAviso = (e) =>{ 
        //e.stopPropagation();              
        console.log("Aviso")
        sessionStorage.removeItem('aviso');
        sessionStorage.removeItem('situacion')
        sessionStorage.setItem('aviso', JSON.stringify(aviso));   

    }
    
    const renderNavButton = () => {
      if (aviso.KM != "0") {
        return <div className={styles.map} onClick={handleClickMapa}><Button leftIcon={<MdMap />} variant='outline' className={styles.button}>
        Ir
        </Button>     
    </div>;
      } else {
        return <div>&nbsp;</div>;
      }
    }

    return(
        <div className={styles.avisoitem} onClick={handleClickAviso}>
            <div>
                
            <NavLink to={ROUTES.AVISO.link} className="cursor-pointer text-black-400" >
                            <div className={styles.footer}>

                                <div>
                                    <u>Aviso:</u>&nbsp;{aviso.CODIGOAVISO}
                                </div>
                                <div>
                                    <u>Fecha:</u>&nbsp;{aviso.FECHAAVISO}&nbsp;{aviso.HORAAVISO}
                                </div>
                                <div>
                                    <u>N.Serie:</u>&nbsp;{aviso.NUMSERIE}
                                </div>
                                <div>
                                    <u>Descripción:</u>&nbsp;{aviso.NOMBREART}
                                </div>
                                <div>
                                    <u>Fecha Venta:</u>&nbsp;{aviso.FECHAFRADO}
                                </div>
                                { aviso.ORIGEN != aviso.NOMBRE? 
                                   
                                        <div>
                                            <div>
                                                <u>Origen:</u><b>&nbsp;{aviso.ORIGEN}</b>
                                            </div>
                                        </div> : null
                                }
                                <div>
                                    <u>Cliente:</u>&nbsp;{aviso.NOMBRE}
                                </div>
                                <div>
                                    <u> Dirección:</u>&nbsp;{aviso.DIRECCION}&nbsp;{aviso.POBLACION}&nbsp;{aviso.PROVINCIA}&nbsp;-&nbsp;{aviso.TELEFONO}
                                </div>
                                <div>
                                    <u> Horario:</u>&nbsp;{aviso.HORARIO}
                                </div>
                                <div>
                                <div className={styles.km}><u>Distancia:</u>&nbsp;{aviso.KM}&nbsp;Km </div>
                                {renderNavButton()}
                                </div> 
                                <div  className={styles.incidencia}>
                                    <u>Situación:</u>&nbsp;{aviso.SITUACION}
                                </div>                
                                <div  className={styles.incidencia}>
                                    <u>Incidencia:</u>&nbsp;{aviso.INCIDENCIA}
                                </div>            
                            </div>        
                       
                </NavLink> 
                
            </div>
        </div>





        
    )
}

export default AvisoItem
