import React from "react"
import MapsForm from "../../components/features/Maps"
const Map = () => {  
    
        
        return(
        <div className="xs:pt-8 flex items-center align-center justify-center" >
            <MapsForm />
        </div>        
    )
}
export default Map