import CustomersPage from "./Customers"
import HomePage from "./Home"
import FeaturesPage from "./Features"
import ProductsPage from "./Products"
import SalesPage from "./Sales"
import LoginPage from "./Login"
import AvisosPage from "./Avisos"
import AvisosDistance from "./AvisosDistance"
import MapsPage from "./Map"
import AvisoPage from "./Aviso"

export default HomePage;
export { FeaturesPage, ProductsPage, SalesPage, CustomersPage, LoginPage, AvisosPage, AvisosDistance, MapsPage, AvisoPage }
